pro/* TOZI E POSLEDNIQ */

body {
    font-size: 14px;
}
.themeMain {
    background-color: white;
}
.content {

}
.school-course{
    display: none;
}
.redLabel{
    background:#ff4d4d;
    border-radius:2px;
    margin:1px;
    color:white;
    padding: 2px;
    font-weight: bold;
}
.label-exercise-name{
    margin-left: 8px;
    font-weight: bold;

}
.txtarea-style{
    width: 100%;
    height: 180px;
    border: 3px solid #cccccc;
    padding: 5px;
    font-family: Tahoma, sans-serif;

    background-position: bottom right;
    background-repeat: no-repeat;
}
.greenLabel{
    background:green;
    border-radius:2px;
    color:white;
    padding: 2px;
    font-weight: bold;
}
.school-teacher-btn{
    color:#666;
    font-weight: bold;
    font-size: 12px;
    background-color: #9cc;
    border:none;
    cursor: pointer;
    border-radius: 3px;
}
.school-teacher-btn:hover{
    background-color: #FFA500;
    color:white;
}
.school-edit-btn-font{
     font-size: 16px;
}
.advance-panel{
    display:none;
}
#coursesList{
    font-family: monospace;
}
.langLetters {
    text-transform: uppercase;
    width: 100%;
    text-align: center;
}
.langLettersFlags {
    text-transform: uppercase;
    display:inline-block;
    height:15px;
    width:20px;
    text-align: center;
    line-height:15px;
    vertical-align: top;
    color:#fff;
}

.langBackGround {
    position: absolute;
    top: -5px;
    left: -5px;
    bottom: -5px;
    right: -25px;
    background-color: white;
    z-index: -1;
    border-radius: 4px;
}
#language{
    border:2px solid transparent;
}
.languageBar {
    display: inline-block !important;
    position: absolute;
    right: -5px;
    top: 20px !important;
    background-color: white;
   /* NEW VALUE */
   width:50px;
   z-index:12;
   box-sizing: initial;
       padding-left: 5px;
}

.langFlag{
    display:inline-block;
    width:20px;
    /*height:15px;*/
    height:13px;

}
.languageBar a {
    display: block;
    margin: 0;
    padding: 0;
    
}
.languageBar a:nth-child(2) {
    margin-top:3px !important;
}
#languageSelected{
  background: #007BFF;
  border: 2px solid #fff;
  z-index: 24;
  padding-left: 5px;
  box-sizing: initial;
}
.languageBar{
  background: #007BFF !important;
}
.languageBar img {
    border: 1px solid #999;
}
.languageArr {
    position: absolute;
    right: -15px;
    top: 60%;
    transform: translateY(-50%);
    font-size: 1rem;
    text-align: center;
}
#languageSelected {
    cursor: pointer;
}
.flagLine {
    width: 20px;
    display: inline-block;
}

.singleLanguage div:first-of-type {
    width: 20px;
}
.singleLanguage:last-of-type div{
    margin: 0;
}

.textSize-title {
    font-size: 3.2rem;
}
.textSize-smallTitle {
    font-size: 2.4rem;
}
.textSize-subTitle {
    font-size: 1.6rem;
}
.textSize-p {
    font-size: 1.2rem;
}
.infoSmallText {
    font-size: 0.6rem;
}
/* --- Commons --- */
a, a:link, a:hover, a:visited, a:active {
    color: inherit;
    text-decoration: none;
}
.inline {
    display: inline-block;
}
.noMargin {
    margin: 0;
}
.padding_80 {
    padding-top: 80px;
    padding-bottom: 80px;
}
.padding_60 {
    padding-top: 60px;
    padding-bottom: 60px;
}
.padding_40 {
    padding-top: 40px;
    padding-bottom: 40px;
}
.padding_20 {
    padding-top: 20px;
    padding-bottom: 20px;
}
.padding_10{
    padding-top: 10px;
    padding-bottom: 10px;
}
.padding16px {
    padding: 16px;
}
.relative {
    position: relative;
}
.absolute {
    position: absolute;
}
/* --- Commons - Fonts --- */
.serif {
    /*font-family: FactSlabPro, serif;*/
    font-family: "Lucida Console", Monaco, monospace;
}
.sans_serif {
    font-family: Verdana, sans-serif;
}
.georgia{
    font-family:Georgia,serif;
}
/* --- Common - Align --- */
.left {
    float: left;
}
.right {
    float: right;
}
.clear {
    clear: both;
}
.valign_top{
    vertical-align: top;
}
.valign_bottom {
    vertical-align: bottom;
}
.align_left {
    text-align: left;
}
.align_right {
    text-align: right;
}
.align_center {
    text-align: center;
}
.justifyFlex {
      /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flex; /* Safari */
    -webkit-justify-content: space-between; /* Safari 6.1+ */
    -webkit-flex-wrap: wrap; /* Safari 6.1+ */
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: stretch;
    -webkit-align-items: stretch;
}
.justifyFlexCenter{
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-justify-content: space-between;
    -webkit-flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    -webkit-align-items: center;
    align-items: center;

}
.justifyFlexAround {
    display: -webkit-flex; /* Safari */
    -webkit-justify-content: space-around; /* Safari 6.1+ */
    -webkit-flex-wrap: wrap; /* Safari 6.1+ */
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: stretch;
}
/* --- Common - Classes --- */
.cursor {
    cursor: pointer;
}

/* --- Order Classes --- */

.z10 {
    z-index: 10;
}
.z-1 {
    z-index: -1;
}

/* --- Common - Buttons --- */
.tileActoin {
    text-transform: uppercase;
    font-weight: 700;
    padding: 8px 20px;
}
.button, .inactiveButton {
    display: inline-block;
    padding: 8px 20px;
    border: none;
    cursor: pointer;
}
.inactiveButton {
    cursor: auto;
    background-color: #999;
    box-shadow: none;
}
.button:hover {
    transform: translateY(-2px);
    box-shadow: 1px 1px 3px #999;
}
.button:active {
    transform: translateY(1px);
    box-shadow: 1px 1px 1px #666;
}
.inactiveButton:hover {
    transform: translateY(0px);
    box-shadow: none;
}
.inactiveButton:active {
    transform: translateY(0px);
    box-shadow: none;
}

/* --- Common input styles --- */

.inputWrap {
    position: relative;
    margin-bottom: 40px;
}
.placeholder {
    text-transform: uppercase;
    font-size: 1rem;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
.modern_input {
    border-radius: 0;
    border: none;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #039be6;
    background-color: transparent;
}
.modern_inputInactive {
    border-bottom-color: transparent;
}
.modern_input:focus {
    background-color: transparent;
    outline: 0;
}
.modern_input:focus::-webkit-input-placeholder {
    opacity: 0;
}
.modern_input[type=checkbox] {
    border: none;
}
input[type=checkbox] {
    /*visibility: hidden;*/
}
.inputWrap:hover .placeholder, .modern_input:focus + .placeholder, .modern_input:hover + .placeholder{
    opacity: 1;
    top: -12px;
    z-index: 1;
}
.checkDiv, .checkDivSmall {
    position: relative;
    height: 29px;
    width: 29px;
    display: inline-block;
}
.checkDivSmall {
    height: 19px;
    width: 19px;
}
.checkDiv label, .checkDivSmall label{
    cursor: pointer;
    position: absolute;
    width: 25px;
    height: 25px;
    top: 0;
    left: 0;
    background: white;
    border: 2px solid #aaa;
}
.checkDivSmall label {
    width: 15px;
    height: 15px;
}
.checkDiv label:after, .checkDivSmall label:after {
    opacity: 0;
    content: '';
    position: absolute;
    width: 15px;
    height: 8px;
    background: transparent;
    top: 4px;
    left: 4px;
    border-width: 3px;
    border-style: solid;
    border-color: #039be6;
    border-top: none;
    border-right: none;
    -ms-transform: rotate(-45deg);
    -webkit-transform:rotate(-45deg);
    transform: rotate(-45deg);
}
.checkDivSmall label:after {
    border-width: 2px;
    width: 12px;
    height: 5px;
    top: 2px;
    left: 1px;
}
 .checkDiv label:hover::after, .checkDivSmall label:hover::after {
	opacity: 0.5;
}
.checkDiv input[type=checkbox]:checked + label:after, .checkDivSmall input[type=checkbox]:checked + label:after {
    opacity: 1;
}
.checkLabel {
    font-size: 1.2em;
    vertical-align: bottom;
}
.inactiveText {
    color: #999;
}

.warningTick {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.floatMessageDiv {
    width: auto;
    padding: 5px;
    background-color: white;
    border: 1px solid #999;
    border-radius: 5px;
    position: absolute;
    bottom: 200%;
    left: 0;
}
/* --- MOBILE MENU --- */
.menuTrigger {
    display: none;
}
.mobMenuWrapper {
    display: none;
}
.mobNewLine {
    display: none;
}

/* --- User name and user profile chunk --- */

#username {
    margin-top: 8px;
    font-weight: 200;
    padding: 0px 0px 0px 0px;
    text-align: right;
    display: inline-block;
    font-size: 1rem;
    line-height: 1rem;

}
#username span {
    font-weight: 600;

}
.user_profile{

    -ms-transform: translateY(17%);
    -webkit-transform: translateY(17%);
    transform: translateY(17%);

    float: right;
    
    display: inline-block;
    /*display:inline-block;*/
}
#wrapperMenu #topRightMenu{
    -ms-transform: translateY(0%) !important;
    -webkit-transform: translateY(0%) !important;
    transform: translateY(0%) !important;
    margin-right: 12px ;
}
.avatarTop{
    width: 40px;
    height: 40px;
}
.drop_menu {
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 12;
}
.userLi {
    display: block;
    padding: 10px 20px;
}
.subMenuItem {
    text-transform: none;
    font-size: 0.8rem;
    padding-top: 5px;
    padding-bottom: 5px;
    position:relative;
}
.subMenuItemIcon{
    position:absolute;
    left:29%;
    top:0px;
}
.subMenuItemText{
    padding-left:36%;
}
#user_icons {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
    vertical-align: top;
    border-radius: 50%;
    font-size: 1.6rem;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    overflow: hidden;
}
#user_avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.drop_menu ul {
    margin: 5px;
    padding: 0px;
    list-style: none;
    text-transform: uppercase;
}
/* --- Common --- */
.floater {
    position: absolute;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    font-size: 2rem;
    line-height: 46px;
    text-align: center;
}
.sectionTitle {
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 0;
    color: black;
}
.sectionSubTitle {
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 60px;
    color: #333;
}
/* --- Choose Goals --- */
/* --- 02.02.2016 - new design --- */
#goalHeader {
    display: block;
}
#goal_status {
    background-color: white;
    padding-bottom: 0;
}
#showGoalSteps {
    top: 16px;
    right: 16px;
}
.verticalStripe {
    display: inline-block;
    vertical-align: top;
}
.pathSecTitle {
    position: absolute;
    top: 16px;
    left: 16px;
    margin: 0;

}

.vertSeparator {
    border-left: 1px solid #ccc;
}
.showPathsInGoalsButton {
    top: 20px;
    left: 0;
    transform: translateX(-50%);
}
/* --- end of new design --- */
.sc{
    background:#c66;
}
.tc{
    background:#FFA500;
}
.foc{
    background:#3F51B5;
}


/* ---- Float Menu Styles ---- */
.floatMenuWrapper {
    background-color: rgba(255,255,255,1);
    border: 1px solid #ccc;
    z-index: 6;/*old 1*/
}
.floatMenu {
    display: block;
    list-style: none;
    padding: 0;
    margin: 0;
}
.floatMenuNew{
    position:absolute;

    top:-webkit-calc(100% + 15px);
    top:-moz-calc(100% + 15px);
    top:-ms-calc(100% + 15px);
    top:-o-calc(100% + 15px);
    top:calc(100% + 15px);
    right:0;
    width:100%;
}
.floatMenuWithoutPointer{
    width:250%;
}
.floatMenu li {
    display: inline-block;
}
.floatMenuItem {
    display: block;
    padding: 8px 16px;
    font-size:1rem;
}
.floatMsgBox {
    padding: 16px 16px 16px 16px;
    font-size: 1rem;
    color: #666;
}
.warningIcon {
    margin-right: 16px;
}
.closePrompt, .closePromptNew {
    padding: 4px 8px;
    cursor: pointer;
    position: absolute;
    top: -7px;/*old 0px */
    right: -7px;/*old -25px*/
    color: red;
    font-size: 1rem;
}
.closePromptSubscription{
    padding: 4px 8px;
    cursor: pointer;
    position: absolute;
    top: -4px;
    right: 0px;
    color: red;
    font-size: 1rem;
}
.squarePointer {
    position: absolute;
    background-color: rgba(255,255,255,1);
    -ms-transform: translateY(-50%) rotate(45deg);
    -webkit-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg);
    top: 0;
    left: 10%;
    width: 20px;
    height: 20px;
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
}
/* USING THIS NEW CLASS FOR POINTER FOR FIXING A BUG*/
.squarePointerNew{
    position: absolute;
    background-color: rgba(255,255,255,1);
    -ms-transform: translateY(-50%) rotate(45deg);
    -webkit-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg);
    top: 0;
    left: 10%;
    width: 20px;
    height: 20px;
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
}
.squarePointerRight{
    left:auto;
    right: 10%;
}
.squarePointerLeft{
    left:10%;
}
.spMirrored {
    left: auto;
    right: 20px;
}

/* --- HELPER in Goals --- */


.padding_80 {
    padding-top: 40px;
    padding-bottom: 40px;
}
.msg-con {
    color: red;
}
/* --- Editor elements' z-index fixed --- */

.editorsDiv {
   overflow: scroll;
    z-index: 0;
}
.ace_editor {
    font-family: monospace !important;
}
.ace_editor div {
    font: inherit !important;
}

.thanks{
    font-style: italic;
    font-weight: bold;
    font-size:1.2rem;
    margin-top:15px;
}
.toProfile{
    display:inline-block;
    padding:15px;
    background:#099;
    color:#fff !important;
    cursor:pointer;
    border-radius: 10px;
    border-bottom:5px solid #015050;
}

.whatsapp{
    background-image:url('../images/whatsapp.png');
    width: 30px;
    height: 30px;
    display: inline-block;
    background-size: cover;
    background-repeat: no-repeat;
}


@media screen and (max-width: 1440px) {
    .underGraph #dailyGoalsList #modules li span.dailyModuleChoose{
        width:73%;
        display:inline-block;
    }
    .underGraph #dailyGoalsList #modules li .checkDivSmall{
        vertical-align: top;
    }
}
@media screen and (max-width:1366px){
    .checkDiv label, .checkDivSmall label{
        width:18px;
        height:18px;
    }
    .checkLabel{
        font-size:1.1em;
    }
    .checkDiv label:after, .checkDivSmall label:after{
        width: 12px;
        height: 6px;
        top: 3px;
        left: 2px;
    }
    #agreeCheckBox{
        visibility:hidden;
    }
    .placeholder{
        font-size:1em;
    }
    .inputWrap{
        margin-bottom:20px;
    }
    .textSize-title{
        font-size:2.9rem;
    }
}
@media screen and (max-width: 1280px) {
    /* STYLES FOR MODULE NAMES IN SET GOLS PABEL*/
    .underGraph #dailyGoalsList #modules li span.dailyModuleChoose{
        width:66%;
        display:inline-block;
    }
    .underGraph #dailyGoalsList #modules li .checkDivSmall{
        vertical-align: top;
    }
    #helperStep3{
        right:0;
    }
}
@media screen and (max-width: 1024px) {
    #gifsPanel .padding-right-10{
        padding-right:0;
    }
    #gifsPanel .padding-left-10{
        padding-left:0;
    }
    .languageBar {
        top: 5px;
    }

    .languageArr {
        font-size: 0.8rem;
        bottom: -12px;
    }
    #user_icons {
        height: 26px;
        width: 26px;
        font-size: 1.2rem;
        line-height: 26px;
    }
    #username {
        margin-top: 4px;
        font-size: 0.8rem;
        line-height: 0.7rem;
    }
    #helperStep3 {
        right: 0px;
    }
    .floatMenuNew{
        width:100%;
    }
    ul#setModules li .dailyModuleNameChoose,ul#setModulesUp li .dailyModuleNameChoose{
        width: 70%;

    }
    /* NEW */
    #user_avatar{
        width:100%;
        height:100%;
    }
    /* STYLES FOR MODULE NAMES IN SET GOLS PABEL*/
    .underGraph #dailyGoalsList #modules li span.dailyModuleChoose{
        width:55%;
        display:inline-block;
    }
    /*select#coursesList option{
        width:100%;
    }*/


}
@media screen and (max-width: 780px) {
    .right_ad{
        background-size: contain;
        height: 157px;
        width: 108px;
    }
    #promotion_modal{
        width:90%;
        margin-left:0;
    }
    .heading-share{
        font-size:1.8rem;
    }
    .class780 {
        width: 100%;
    }
    .mobNewLine {
        display: block;
    }
    #user_icons {
       /* margin-right: 37px !important;*/
        margin-right:0px !important;
        cursor: default;
        transition: none;
    }
    #user_icons:hover {
        background-color: #039BE6;
    }
    #username {
        display: none;
        position: absolute;
        line-height: 1rem;
        margin-top: 0;
        font-size: 1rem;
        right: 54px;
        top: 50%;
        transform: translateY(-50%);
    }
    .userStatus {
        display: none;
    }
    .menuTrigger {
        font-size: 1.8rem;
        display: inline-block;
        position: absolute;
        right: 0;
        top: 50%;
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .adminUlHeader {
        display: none;
    }
    #topRightMenu {
        display: none;
    }
    .mobMenu {
        margin: 0;
        padding: 0;
        list-style: none;
        background-color: #999;
        -webkit-box-shadow: inset 2px 2px 4px 0px rgba(0,0,0,0.5);
        -moz-box-shadow: inset 2px 2px 4px 0px rgba(0,0,0,0.5);
        box-shadow: inset 2px 2px 4px 0px rgba(0,0,0,0.5);
    }
    .mobMenu li {
        padding: 0 0 0 50px;
        color: white;
    }
    .mobMenu li:active {
        background-color: #ccc;
    }
    a.mobMenuLi {
        text-transform: uppercase;
        display: block;
        padding: 16px 0;
    }
    .padding_80 {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .padding_80 {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .goalSecTitle {
        font-size: 0.7rem;
    }
    #goalsPathList {
        display: none;
    }
    .inputWrap:hover .placeholder, .modern_input:focus + .placeholder, .modern_input:hover + .placeholder{
        top: -23px;
    }
    .placeholder{
        font-size:0.8rem;
    }
    .floatMenuNew{
        /*right:15%;*/
        width:85%;
    }
    .floatMenuCourseStructure{
        width:115%;
    }
    .top_row{
        margin-top:23px;
    }
    #showGoalSteps {
        display: none;
    }
    #goalInfoBit {
        display: block;
    }
    #clearTsks, #okGoalsButton, #startTsks {
        width: 20%;
        text-align: center;
        margin-bottom: 10px;
    }
    .textGoal400 {
        display: inline;
    }
    .graphText, .graphTextInEx {
        position: relative;
        font-weight: 500;
        transform: translateX(-40%);
        text-align: center;
        width: 100%;
        top:0; /* new */
    }
    .graphCont, .graphCont canvas  {
        margin: 0 auto;
        display: block;
    }
    /** NEW GRAPH SWIDTH */
    .goalsWrap .goalGraph .graphCont{
        width:100%;
    }
    .goalSecTitle {
        display: block;
        width: 100%;
    }
    #cvsb[style] {
        width: 70% !important;/* old 80%*/
    }
    #cvsc[style] {
        width: 70% !important;/* old 80%*/
    }
    .goalsWrap {
        display: flex;
        justify-content: space-around;
    }
    .underGraph  {
        width: 80%;
    }
    /*==== Goals Wrapper in Mobile ======*/

    #changeGoalWrapper .goalsWrap.justifyFlexAround{
        flex-direction:column;
    }
    #changeGoalWrapper .goalsWrap .goalGraph{
        display: inline-flex;
        width: 100%;
    }
    #changeGoalWrapper .goalsWrap .graphTextInEx{
        top:20px;
    }
    #changeGoalWrapper .goalsWrap .graphText{
        left:19%;
        font-size:1.2rem;
    }
    #changeGoalWrapper .goalsWrap .graphCont{
        width:auto;
    }
    #changeGoalWrapper .goalsWrap ul.goalListUl li .line-chart{
        margin-right:10px;
    }
    #changeGoalWrapper .padding_40{
        padding-top:28px;
    }
    #changeGoalWrapper #goal_status{
        height:495px;
        overflow-y:scroll;
    }
    .mobProfile{
        position:absolute;
        top:-15px;
        right:8px;
        z-index:8;
        overflow: visible;
    }
    .mobProfile #user_icons{
        margin-right: 0;
        width:30px;
        height:30px;
        font-size:1.3rem;
        line-height:29px;
    }
    .mobProfile #languageSelected{
        margin:0 5px;
    }
    .mobProfile .mobLanguage{
        margin:0 5px;
    }
    #mobExNavigation h2{
        width:75%;
        word-wrap: break-word;
    }
    ul#setModulesUp li .dailyModuleNameChoose{
        display:block;
        width:auto;
    }

    ul#setModulesUp li .lineChart{
        float: none;
    }
    #dailyGoalsList ul.goalListUl li .lineChart{
        margin-right: 16px;
    }
    #dailyGoalsList ul.goalListUl li .checkDivSmall{
        float:left;
    }
    ul.goalListUl li .dailyModuleChoose{
        display:inline-block;
        width: 55%;
        word-wrap:break-word;
    }
    ul.goalListUl li .payStatus{
        margin-left:5px;
    }
    .user_profile{
        -ms-transform:translateY(8%);
        -webkit-transform:translateY(8%);
        transform:translateY(8%);
    }

}
@media screen and (max-width:480px){
    .user_profile{
        -ms-transform:none;
        -webkit-transform: none;
        transform:none;
    }
}
@media screen and (max-width: 400px) {
    /*.closePromptSubscription{
        right: -32px;
    }*/
    #showGoalSteps {
        display: none;
    }
    #goalInfoBit {
        display: block;
    }
    #clearTsks, #okGoalsButton, #startTsks {
        width: 20%;
        text-align: center;
        margin-bottom: 10px;
    }
    .textGoal400 {
        display: inline;
    }
    .graphText, .graphTextInEx {
        position: relative;
        font-weight: 500;
        transform: translateX(-40%);
        text-align: center;
        width: 100%;
    }
    .graphCont, .graphCont canvas  {
        margin: 0 auto;
        display: block;
    }
    .goalSecTitle {
        display: block;
        width: 100%;
    }
    #cvsb[style] {
        width: 80% !important;
    }
    #cvsc[style] {
        width: 80% !important;
    }
    .goalsWrap {
        display: flex;
        justify-content: space-around;
    }
    .underGraph  {
        width: 80%;
    }
}

/*=== MEDIA QUERY FOR MOBILE AND LANDSCAPE ORIENTATION ===*/
@media (max-width:780px) and (orientation: landscape){

     #changeGoalWrapper .goalsWrap.justifyFlexAround{
        flex-direction:row;

    }
     #changeGoalWrapper .goalsWrap .goalGraph{
        flex-direction:column;
    }
     #changeGoalWrapper .goalsWrap .goalGraph{
        width: auto;
    }
     #changeGoalWrapper .goalsWrap .graphText{
        left:50%;
    }
     #changeGoalWrapper #goal_status{
        height:250px;
        overflow: scroll;

    }
     #changeGoalWrapper .padding_40{
        padding-top:25px;
    }
     #changeGoalWrapper .goalsWrap #cvsc[style]{
        width:50% !important;
    }
     #changeGoalWrapper .goalsWrap #cvsb[style]{
        width:50% !important;
    }

}
#restriction_window .overlayDark{
    z-index:45;
}
#restriction_modal{
    position:fixed;
    padding:10px 25px;
    top:20%;
    left:50%;
    width:50%;
    background: #fff;
    z-index: 46;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform:translate(-50%,-50%);
    transform: translate(-50%,-50%);
    font-weight:bold;
    text-align: center;
    font-size:2rem;
}
#restriction_modal .button{
    display:block;
    width:150px;
    text-align:center;
    font-weight: bold;
    cursor: pointer;
    border-radius: 20px;
    margin:0 auto;
    font-size:1rem;
    margin-top:25px;
}
#closeRestrictionModal{
    color:red;
    position:absolute;
    top:7px;
    right:8px;
    cursor:pointer;
}
.overlay_full, .overlayDark {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 20;
    display: none;
}
.billInfo{
    padding:10px;
    font-weight:bold;
}
.downloadBill{
    position:absolute;
    right:2px;
    top:10px;
    background:#FFA500;
    color:#fff;
    padding:5px 15px;
    border:none;
    font-weight:bold;
    border-radius: 15px;
    cursor: pointer;
    outline:none;
}
.bills-info-container{
    position:relative;
}

.custom-fb-btn{
    background: #365899;
    border: 1px solid #365899;
    border-radius: 3px;
    font-size: 15px;
    font-weight: bold;
    padding: 0 0 3 3px;
    cursor: pointer;
    color:white;
}
.promotion-head{
  text-align: center;
}
.promotion-tile{
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    border-radius: 3px;
}
.center-text{
    text-align: center;
}
.promotion-course-link{
        color: #039be6;
}
.promotion-course-link:hover{
    text-decoration: underline;
}
.subsc{
   display: none;

}
.pin-tag{
    font-size: 36px;
    color:#000;
    transform: rotate(30deg);
}
.upper{
    text-transform: uppercase;
}
.import-msg{
    font-weight: bold;
    color:red;
}
.start-popup-modal{
    position: fixed;
    background: #fff;
    display: none;
    top: 50%;
    width: 70%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    left: 50%;
    z-index: 31;
    border-radius: 5px;
    padding: 25px;
    text-align: center;
}
.approve-popup-modal{
    position: fixed;
    background: #fff;
    top: 50%;
    width: 70%;
    transform: translate(-50%,-50%);
    left: 50%;
    z-index: 31;
    border-radius: 5px;
    padding: 25px;
    text-align: center;
}
.start-btn-pop{
    background: -webkit-linear-gradient(top,#fff 0,#89cced 2%,#41ade3 100%) !important;
    color:white;
    border:none;
    font-weight: bold;
    padding: 8px;
    text-transform: uppercase;
    border-radius: 30px;
    width: 140px;
    cursor: pointer;
    border:2px solid transparent;
}
.resend-btn-pop{
    background: -webkit-linear-gradient(top,#fff 0,#89cced 2%,#41ade3 100%) !important;
    color:white;
    border:none;
    font-weight: bold;
    padding: 8px;
    text-transform: uppercase;
    border-radius: 30px;
    width: 140px;
    cursor: pointer;
    border:2px solid transparent;
}

.start-btn-pop:hover{
    border: 2px solid #FF9F00;
}
.new_password-label{
    width: 20%;
    margin:0 auto;
}
.email-pop-input{
    font-size: 20px;
    width: 400px;
}
.email-resend-input{
    font-size: 1.5rem;
    width: 400px;
    font-weight: lighter;
}
.label-email{
    font-size:1.5rem;
    font-weight:bold;
}
.email-subscribe{
    background:url('../images/email-subscribe.png');
    background-size:contain;
    background-repeat: no-repeat;
    /*background-position:*/
}
.color-border{
   border-bottom: 6px solid #3F51B5;
   border-top: 6px solid #FFA500;
   border-left: 6px solid #c66;
   border-right: 6px solid #3DB0A1;
   height: 240px;
}
.start-popup form{
   top:50px;
   position:relative;
}
.roket-pop{
    position:absolute;
    top:40%;
    left:5%;
    color:#666;
}
.map-setion{
    bottom: -58px;
    position: relative;
}
.share-btn-course{
    font-size: 12px;
    z-index: 20;
    left:10px;
    top:10px;
    position: absolute;
}

.confirm-popup{
    position: fixed;
    background: #fff;
    top: 50%;
    width: 70%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    left: 50%;
    z-index: 31;
    border-radius: 5px;
    padding: 25px;
    text-align: center;
}
.activate_now{
    color:#039CE6;
}
@media screen and (max-width: 380px){
    .share-btn-course{
        top:4px;
        right:0;
    }
}
