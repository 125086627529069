/*== 1st section - PAYMENT PLAN ==*/
#companyAccount.inactive{
    display:none;
}
#personalAccount.inactive{
    display:none;
}
.switch_wrapper {
    display: inline-block;

}

.plan {
    display: inline-block;
    font-weight: 200;
    color: #999;
    text-transform: uppercase;
    font-size: 2em;
    margin: 0px 20px;
}
.personal_company {
    text-align: left;
    /*margin: 30px 10%;*/

}
.api, .account {
    cursor: pointer;
    display: inline-block;
    font-weight: 500;
    background-color: #099;
    color: white;
    text-transform: uppercase;
    font-size: 1.4em;
    padding: 5px 40px;
    margin: 0px 20px;
    border-radius: 20px;
}
.api:hover, .account:hover {
    transform: translateY(-1px);
    box-shadow: 1px 1px 1px #666;
}

.switch_button {
    display: inline-block;
}
.switch_base {
    background-color: #ddd;
    width: 48px;
    height: 16px;
    border-radius: 10px;
    box-shadow: inset 1px 2px 5px #999;
}
.switch_top {
    transform: translate(-25%, -25%);
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: #ccc;
    box-shadow: 1px 1px 3px #999, inset -1px -2px 5px #999;

}
.switch_position {
    transform: translate(75%, -25%);
}

.pointer {
    cursor: pointer;
}

.pay_box {
    text-align: left;
    margin: 0px 0px;
    display: inline-block;
    /* width: 80%; */
    background-color: #fbfbfb;
    color: #333;
    /* box-shadow: 1px 2px 3px #666; */
    box-shadow: 0 2px 24px rgba(0,0,0,0.4);

}
.pay_box_left {
    padding: 30px;
    width: 30%;
    display: inline-block;
    vertical-align: top;
    margin-top:70px;
}
.per_time {
    cursor: pointer;
}
.per_on {
    font-weight: 500;
    color: #333;
}
.paid_message {
    /*margin: 20px;*/
}
.enroll_now {
    color: #099;
    font-size: 2em;
    /*margin-top: 50px;*/
    border: 1px solid #099;
    border-radius: 50px;
    padding: 10px 0px;
    cursor: pointer;
    text-align:center;
}
.enroll_now:hover {
    background-color: #099;
    color: white;
    font-weight: 500;
}
.plan_description .enroll_now{
    font-size:1rem;
}
.pay_box_right {
    width: 50%;
    padding: 30px;
    vertical-align: top;
    display: inline-block;
}
.pay_box_right ul{
    list-style-type: none;
    padding: 0px;
}
.pay_box_right ul li{
    margin: 5px 0px;
}
.feature_heading {
    font-size: 1.2em;
    font-weight: 500;
    font-style: italic;
}
#for_people {
    margin-top: 20px;
}
#people_count {
    width: 56px;
    padding: 3px;
}
.pay_price {
    color: #099;
    font-size: 6em;
    font-weight: 300;
}
.pay_box_left > p {
    color: #999;
    margin: 0px;
    font-size: 1.2em;
}
.pc_on {
    font-weight: 500;
    color: #099;
}
.pc_off {
    font-weight: 200;
    color: #999;
}
.thanks_line p{
    font-size: 3em;
    font-weight: 200;
    color: #666;
}
.thanks_line span {
    font-size: 0.5em;
}
.thanks_button {
    width: 30%;
    display: inline-block;
    margin-bottom: 50px;
}
.thanks_box{
    text-align: center;
}
.thanks_box .pay_box{
    text-align: center;
    padding:20px;
}
/*== 1st section - PAYMENT_FORM ==*/

.payment-wrapper {
    width: 1200px;
    margin: 0 auto;
    font-weight: bold;
    font-family: "Open Sans";
    text-align: left;
}

.form-section {
    background-color: #f7f8fa;
    border-radius: 5px;
    border-top: 5px solid #18c784;
    padding: 20px;
    width: 80%;
    margin: 0px auto;
}

.icon {
    height: 40px;
    position: relative;
    top: 10px;
}

input[type=radio] {
    /* All browsers except webkit*/
    transform: scale(1.5);
    margin-left: 40px;
    /* Webkit browsers*/
    -webkit-transform: scale(1.5);
}

.text-field {
    width: 98%;
    margin-bottom: 20px;
}

.payment-section-title {
    font-size: 35px;
    color: #8e8f91;
    text-align: center;
}

.empty-div {
    height: 40px;
}

.text-description {
    margin-bottom: 10px !important;
}

.subscribe-button {
    background-color: #19c684;
    padding: 10px 30px;
    color: #fff;
    border-radius: 3px;
    border: none;
    margin: 20px auto;
    margin-bottom: 5px;
    display: block;
}

.secure-payment-label {
    color: #8d8e90;
    width: 160px;
    margin: 0 auto;
}

.locker-icon {
    height: 20px;
    position: relative;
    top: 3px;
}

/*.stripe input{
    width: 284px;
    padding: 15px 10px;
    border-radius: 5px;
}*/

select {
    /*padding: 10px;
    border-radius: 5px;
    width: 100px;
    margin-top: 20px;*/
}

.terms {
    color: #e15f5d;
    margin: 20px 0px;
    text-decoration: none;
}

.expiration-date {
    margin-top: 20px;
}

.sign-in {
    text-decoration: none;
    color: #e15f5d;
}
div.submit_payment {
    margin-left: auto;
    margin-right: auto;
    color: white;
    width: 26%;
    background-color: #099;
    border-radius: 5px;
    padding: 8px 20px;
    cursor: pointer;
}

/*payment logos*/
.stripe-logo{
    width: 4%;
   margin-right:10px;
}
.epay-logo{
    width: 8%;
   margin-right:10px;

}
.easypay-logo{
    width: 3%;
   margin-right:10px;

}
#payment_logos{
    display:inline-block;
    position:relative;
}
.cologo{

    position:absolute;
    left: 135px;
    top:-11px;
    width: 11%;
}
.bank_transfer{
   display:inline-block;
   -ms-transform:translateY(-50%);
   -webkit-transform:translateY(-50%);
   transform:translateY(-50%);
        color:#095df4;
        font-size:17px;
        font-weight: bold;

}
.alipay-logo{
    width: 5%;

}
.bill-left{
    width:50%;
}
.bill-right{

}
#restriction_modal #adyen{
    /*background:url('../images/payment-logos.png') no-repeat;*/
}
.stripe-logo-btn{
    width:73px;
}
#restriction_modal .paymentBtns{
    margin:5px;
    position:relative;

}
#restriction_modal .button{
    display:block;
}
#restriction_modal .paymentBtns .methodPayment{
    position:absolute;
    top:20px;
    text-transform: uppercase;
    font-weight:bold;
    color:#099;
    margin-left:15px;
}
#restriction_modal #adyen_logo{
    width: 40px;

}
#restriction_modal #easypay_logo{

}
#easy-pay-form .paymentLabel{
    font-size:1.5rem;
    width:100%;
    margin:0 auto;
    font-weight:500;
}
#easy-pay-form .modern_input{
    width:100%;
    padding:10px;
}
#easy-pay-form .inputWrap{
    margin-bottom:10px;
}
#easy-pay-form .radioButtonsPayment .pay-radio-btn{
    float:left;
    margin-left:0;
    margin-right:10px;
}
#easy-pay-form .radioButtonsPayment{
    margin-top:5px;
    margin-bottom:5px;
}
#easy-pay-form .inputWrap:hover .placeholder, .modern_input:focus + .placeholder, .modern_input:hover + .placeholder{
    opacity: 1;
    top: -5px;
    z-index: 1;
}
#easy-pay-form #payinfo-btn-submit{
    text-transform: uppercase;
    height: 55px;
    margin-top: 20px;
    font-size:1rem;
    border:none;
    text-align:center;
    margin:0 auto;
    width:100%;
    margin-top:20px;
}
#easy-pay-form #easy-pay-btn-submit{
    text-transform: uppercase;
    height: 55px;
    margin-top: 20px;
    font-size:1rem;
    border:none;
    text-align:center;
    margin:0 auto;
    width:100%;
    margin-top:20px;
}
.visa-mastercard{
    width:104px;
    position: absolute;
    left: 235px;

}
.econt{
    position: absolute;
    left: 350px;
    top: 2px;
    color: #095df4;
    font-size: 17px;
}
.billing-features{
  padding:80px 0;
}
.featureBox{
  width:24%;
  display: inline-block;
  text-align: center;
  /*box-shadow:1px 2px 3px #666;*/
  box-shadow:0 2px 24px rgba(0,0,0,0.4);
  position: relative;
  padding:40px 5px;
  padding-bottom:10px;
  box-sizing: border-box;
  margin-left:5px;
  margin-right:5px;
}
.featureIcon{
    font-size: 4.5rem;
    border-radius: 50%;
    background: #fff;
    width: 100px;
    height: 100px;
    display: inline-block;
    position: absolute;
    top:0;
    left:50%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);

    border:4px solid #eaeaea;
    padding:10px 10px;
    /*padding-left:10px;
    padding-right:10px;
    padding-top:10px;
    padding-bottom:10px;*/
}
.featureIconPercent{
    font-size:1.8rem !important;
    color:green;
    font-weight:bold;
}
.featureIconPercent span{
    position:absolute;
    top:50%;
    left:50%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform:translate(-50%,-50%);
}
.featureIcon .fa-small{
  font-size:2.2rem;

  line-height: 96px !important;
}
.featureIcon .fa-medium{
  font-size:2.2rem;
}
.featureIcon .fa-large{
  font-size:2.4rem;
  line-height: 96px !important;
}
.iconSupport{
  line-height: 96px !important;
}
.billTitle{
  color:#666;
  margin-top:25px;
  font-size:1.3rem;
}
.iconAccess{
  color:#c66;
  line-height: 96px !important;
}
.iconSupport{
  color:#FFA500;
}
.iconUniversity{
  line-height: 96px !important;
  color:#3F51B5;
}
.payment_success{
    background:rgb(0, 153, 153);
    border-radius: 50%;
    padding:15px;
    box-sizing:border-box;
    color:#fff;
    width:65px;
    height:65px;
    margin-right:10px;
    display:inline-block;
}
.payment_fail{
    background:#D84336;
    border-radius: 50%;
    padding:15px;
    box-sizing:border-box;
    color:#fff;
    width:65px;/*40*/
    height:65px;
    margin-right:10px;
    display:inline-block;
}
.payment_success .fa{
    font-size:2rem;
}
.payment_fail .fa{
    font-size:2rem;
}
.plan_prices{
    text-align: center;
    font-size:3.2rem;/*2*/
    /*text-transform: uppercase;*/
    font-weight:normal;
}

.plan_description{
    padding:25px 5px;
    font-size:1.2rem;
}
.subscribe{
    text-align: center;
    color:#039CE6;
    font-size:2rem;
}
.including{
    font-size:3.2rem;/*2*/
    text-align: center;
    font-weight: normal;
}
.including_description{
    text-align: center;
    font-size:1.2rem;
}
.pricing_table{
   color:#fff;
    margin-top:20px;
}
.pricing_table .row{
    width:100%;
}
.pricing_table td{
     border:1px solid #000;
}
.free_col{
    background:#FFA500;
    font-weight: bold;
    font-size:1.2rem;
}
.premium_col{
    background:#039ce6;
    font-weight: bold;
    font-size:1.2rem;
}
[class*="col-"] {
    float: left;
    padding: 15px;
    box-sizing:border-box;
}
.payment_plan{
    cursor:pointer;
}
.payment_plan_premium{
    cursor: pointer;
}
.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;text-align: center;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%; font-weight:bold;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}
.desc_col_darker{
    background:rgba(204, 204, 204, 0.69);
    color:#000;
}
.free_col_darker{
    background:rgba(255, 165, 0, 0.58);
}
.premium_col_darker{
    background:rgba(3, 156, 230, 0.58);
}
.desc_col_lighter{
    background:rgba(204, 204, 204, 0.12);
    color:#000;
}
.free_col_lighter{
    background:rgba(255, 165, 0, 0.3);
}
.premium_col_lighter{
    background:rgba(3, 156, 230, 0.3);
}
.row .fa{
    font-size:1.3rem;
}
.row .fa-times{
    color:red;
}
.free_col_darker .fa-check,.free_col_lighter .fa-check{
    color:green;
}
.premium_col_lighter .fa-check, .premium_col_darker .fa-check{
    color:green;
}
.kids-bill{
    text-align: center;
    font-size:3.2rem;/*2.5*/
    font-weight: normal;
}
.kids-bill-subtitle{
    text-align: center;
    font-weight: normal;
    font-size:1.2rem;
}
.app_icons{
    width:80px;
    height:25px;
}
.discountWindowOverlay{
     position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,0.5);
    display:none;
    z-index:21;
}
.discount_window{
    z-index:22;
    background:#fff;
    position:fixed;
    top:50%;
    left:50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    display:none;
    padding:15px;
    text-align: center;
}
.close-share{
    position:absolute;
    top:0;
    right:0;
    color:red;
    cursor:pointer;
}
.fb-share{
    background:#3B5998;
    display:inline-block;
    color:#fff;
    padding:10px;
    cursor:pointer;
    border-radius:5px;
}
.fb-share .fa{
    margin-right:5px;
}
.share-left,.share-right{
    width:49%;
    display:inline-block;
}
.share-left > span,.share-right > span{
    display:block;
    padding:5px;
    font-weight: bold;
}
.checkmark-circle {
  width: 50px;
  height: 50px;
  position: relative;
  display: inline-block;
  vertical-align: top;
  color:#fff;
  position:relative;
  display:none;
   background: #2EB150;
   border-radius: 50%;
}

.checkmark-circle span{
    position:absolute;
    top:50%;
    left:50%;
    -ms-transform:translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform:translate(-50%,-50%);
}
.col-2 .onDescktop{
        display: block;
    }
    .col-2 .onMobile{
        display:none;
    }
    .discount-window{
        text-align: center;
        margin-top:15px;
    }
    #thanks_box{
        margin-top:50px;
    }
@media screen and (max-width: 1280px){
  .featureIcon{
      font-size: 3.5rem;
      width: 70px;
      height: 70px;
  }
  .featureIcon .fa-small{
    font-size:2rem;
    line-height:66px !important;
  }
  .featureIcon .fa-medium{
    font-size:2.2rem;
  }
  .featureIcon .fa-large{
    font-size:2.4rem;
    line-height: 66px !important;
  }
  .iconSupport{
    line-height: 66px !important;
  }
  .iconUniversity{
    line-height: 66px !important;
    color:#3F51B5;
  }
  .iconAccess{
    color:#c66;
    line-height: 66px !important;
  }
  .featureBox{
    width:23%;
  }
}
@media screen and (max-width: 1024px){
    .featureIcon .fa-small{
        font-size:1.5rem;
        line-height:66px !important;
    }
}
@media screen and (max-width: 780px) {
    .col-2 .onDescktop{
        display: none;
    }
    .col-2 .onMobile{
        display:block;
        font-size:1rem;
    }
    .free_col{
        padding: 15px 0 !important;
    }
    .plan{
        font-size:1.2rem;
    }
    .personal_company{
        /*margin: 30px 0;*/
    }
    .pay_box{
        width:100%;
    }
    .pay_box_left{
        width:100%;
        box-sizing:border-box;
    }
    .pay_box_right{
        width:100%;
        box-sizing:border-box;
    }
    .pay_price{
        font-size:7em;
    }
    .api{
        margin:0;
    }
    .right{
        float:none;
    }
    #logos .stripe-logo{
        width:20%;
    }
    #logos .epay-logo{
        width:17%;
    }
    #logos .easypay-logo{
        width:10%;
    }
    #logos .alipay-logo{
        width:20%;
    }
    .featureBox{
        width:100%;
        margin-top:50px;
    }
    .payment_plan{
        text-align: center;
        width:98%;
        box-shadow:0 2px 24px rgba(0,0,0,0.4);
        border-radius: 5px;
        margin-bottom:15px;
    }
    .bank_transfer{
        -ms-transform: none;
        -webkit-transform: none;
        transform:none;
    }
    .plan_prices{
        font-size:2.1rem;
    }
    .including{
        font-size:2.1rem;
    }
    .kids-bill{
        font-size:2.1rem;
    }
    .plan_description .enroll_now{
       position:absolute;
       bottom:0;
       box-sizing:border-box;
       right:0;
       background: #FF9800;
       border: none;
       color: #000;
       font-weight: bold;
       border-top-right-radius:0;
       border-bottom-right-radius: 0;
    }
}
