/* --- Common Shadows --- */
.tileShadowPage {
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    border-radius: 3px;
}
.tileShadowPageHover {
    transition: box-shadow 0.3s;
}
.tileShadowPageHover:hover {
    box-shadow: 0 6px 20px #AFAFAF;
}

/* --- Color Palletes ---*/

/* light-BLUE and ORANGE */
.dark-primary-color    { background: #1976D2; }
.default-primary-color { background: #2196F3; }
.light-primary-color   { background: #BBDEFB; }
.text-primary-color    { color: #FFFFFF; }
.accent-color          { background: #FF9800; }
.primary-text-color    { color: #212121; }
.secondary-text-color  { color: #727272; }
.divider-color         { border-color: #B6B6B6; }

/* blue-GREY and light-BLUE */
.dark-primary-color    { background: #455A64; }
.default-primary-color { background: #607D8B; }
.light-primary-color   { background: #CFD8DC; }
.text-primary-color    { color: #FFFFFF; }
.accent-color          { background: #03A9F4; }
.primary-text-color    { color: #212121; }
.secondary-text-color  { color: #727272; }
.divider-color         { border-color: #B6B6B6; }

/* INDIGO and PINK */
.dark-primary-color    { background: #303F9F; }
.default-primary-color { background: #3F51B5; }
.light-primary-color   { background: #C5CAE9; }
.text-primary-color    { color: #FFFFFF; }
.accent-color          { background: #FF4081; }
.primary-text-color    { color: #212121; }
.secondary-text-color  { color: #727272; }
.divider-color         { border-color: #B6B6B6; }


/* ---- Info Bit Colors ----*/
.infoBitCol {color: #999;}
.infoBitColDark {color: #333;}
.inactiveBG {background-color: #ccc;}
/* ---- Social Colors ----*/
.fbColHover:hover { color: #3b5998; }
.gPlusColHover:hover { color: #d34836; }
.twColHover:hover{ color: #00aced; }

.fbColBGHover:hover { background-color: #3b5998; }
.gPlusColBGHover:hover { background-color: #d34836; }
.twColBGHover:hover{ background-color: #00aced; }


.prColBGLight {
    background-color: #04d7d7;
    color: white;
}
.prColBGLightHover {
    transition: background-color 0.3s, color 0.3s;
}
.prColBGLightHover:hover {
    background-color: rgba(95, 202, 255, 0.2);
    cursor: pointer;
}
.prColBGDark { 
    background-color: #0088cc;
    color: white;
}
prColBGDarkHover {
    transition: background-color 0.3s, color 0.3s;
}
.prColBGDarkHover:hover { 
    background-color: #0088cc;
    cursor: pointer;
}
.prColBG {
    background-color: #099;
    color: white;
}
.prColBGHover {
    transition: background-color 0.3s, color 0.3s;
}
.prColBGHover:hover {
    background-color: #099;
    color: white;
    cursor: pointer;
}
.prColBGHoverSame:hover {
    background-color: #039be6;
    color: white;
    cursor: pointer;
}
.prCol {  
    color: #099; 
}
.prColHover {
    transition: color 0.3s;
}
.prColHover:hover {
    color: #099;
    cursor: pointer;
}
.prColHoverSame {
    transition: color 0.3s;
}
.prColHoverSame:hover {
    color: #039be6;
    cursor: pointer;
}
.prColBorder {
    border-color: #099;
}
.transBorder {
    border-color: transparent;
}
.accColBG {
    background-color: #FFA500;
    color: white;
    transition: background-color 0.3s;
}
.accColBGHover:hover {
    background-color: #FFAA60;
    color: white;
    cursor: pointer;
}
.accColBGHoverSame {
    transition: background-color 0.3s;
}
.accColBGHoverSame:hover {
    background-color: #FFA500;
    color: white;
    cursor: pointer;
}
.accColBGLight {
    background-color: #fe9;
}
.accCol {
    color: #FFA500;
}
.accColHover {
    transition: color 0.3s;
}
.accColHover:hover {
    color: #FFAA60;
    cursor: pointer;
}
.accColHoverSame {
    transition: color 0.3s;
}

.accColHoverSame:hover {
    color: #FFA500;
    cursor: pointer;
}

.accColBorder {
    border-color: #FFA500;
}
.accColBorderHoverSame {
    transition: border-color 0.3s, color 0.3s;
}
.accColBorderHoverSame:hover {
    border-color: #FFA500;
}

@media screen and (max-width: 780px) {
    .accColBGHoverSame:hover{
        background:#039be6;
    }
    .accColHoverSame:hover {
       color:#039be6;
    }
    .prColHover:hover{
        color:inherit;
    }
}